var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import './Launch.css';
import clientConfig from './client.json';
var client = clientConfig;
var ClientJson = /** @class */ (function () {
    function ClientJson() {
        this.description = 'description';
        this.title = 'title';
    }
    return ClientJson;
}());
export var LaunchView = function (props) {
    useEffect(function () {
        setTimeout(function () {
            var _a;
            (_a = document.getElementById('test')) === null || _a === void 0 ? void 0 : _a.click();
        }, 0);
    }, []);
    return (_jsx("div", __assign({ id: "launchContainer" }, { children: _jsxs("div", { children: [_jsx("h1", { children: client.description }), _jsx(Button, { id: "test", size: "massive", color: "blue", circular: true, icon: "play", onClick: function () { return props.Launch(); } })] }) })));
};
