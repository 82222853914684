var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
 * This reference template is designed to showcase the elements used to construct your own
 * application.
 *
 * When developing take care to:
 *  - Retain user interaction to begin audio.
 *  - Understand video sizing and mobile screen orientation.
 
 * See attached documentation for reference. Contact support@pureweb.com with any questions.
 *
 *
 * Copyright (C) PureWeb 2020
 */
import { LaunchStatusType, PlatformNext, UndefinedModelDefinition, DefaultStreamerOptions, StreamerStatus, Resolution, streamResolutionConfiguration } from '@pureweb/platform-sdk';
import { useStreamer, useLaunchRequest, IdleTimeout, VideoStream, System } from '@pureweb/platform-sdk-react';
import qs from 'query-string';
import React, { useEffect, useState, useRef } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Button, Icon } from 'semantic-ui-react';
import useAsyncEffect from 'use-async-effect';
import './App.css';
import clientConfig from './client.json';
import { LaunchView } from './Launch';
import logger from './Log';
var client = clientConfig;
var ClientJson = /** @class */ (function () {
    function ClientJson() {
    }
    return ClientJson;
}());
var ClientOptions = /** @class */ (function () {
    function ClientOptions() {
        // Overridable streamer options
        this.ForceRelay = false;
    }
    ClientOptions.prototype.isValid = function () {
        if (!this.ProjectId) {
            return false;
        }
        if (!this.ModelId) {
            return false;
        }
        return true;
    };
    return ClientOptions;
}());
var LoadingView = function (props) {
    if (props.StreamerStatus === StreamerStatus.Connected || props.StreamerStatus === StreamerStatus.Completed) {
        return _jsx("div", {});
    }
    var content;
    if (props.StreamerStatus === StreamerStatus.NotSupported) {
        content = (_jsx("div", { children: _jsx("h3", { children: "Your browser does not support the necessary WebRTC capabilities." }) }));
    }
    if (props.LaunchRequestStatus.status === LaunchStatusType.Unavailable ||
        props.LaunchRequestStatus.status === LaunchStatusType.Error ||
        props.StreamerStatus === StreamerStatus.Failed) {
        content = (_jsxs("div", { children: [_jsx("h3", { children: "The experience is presently unavailable." }), _jsx("h3", { children: "Please refresh to request a new session." })] }));
    }
    else {
        content = (_jsxs("div", { children: [_jsx("div", { className: "clock" }), _jsx("h3", { children: "Un instant s'il vous pla\u00EEt, l'univers BRM se dessine \u00E0 l'horizon." }), _jsx("h3", { children: "A moment please, the BRM universe is unfolding on the horizon." })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx("img", { className: "logo", src: "./logo.png", alt: "logo BRM Chronographes" }), _jsx("div", __assign({ style: {
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                } }, { children: _jsx("div", __assign({ style: { textAlign: 'center' } }, { children: content })) }))] }));
};
var EmbeddedView = function (props) {
    var videoRef = useRef(null);
    var handle = useFullScreenHandle();
    // Fullscreen API presently supported on iPad, but not iPhone or iPod
    var isIPhone = System.Browser().os === 'iOS' && !window.navigator.userAgent.includes('iPad');
    return (_jsx("div", __assign({ style: { height: '100%' } }, { children: _jsxs(FullScreen, __assign({ handle: handle }, { children: [_jsx(IdleTimeout, { Status: props.StreamerStatus, WarningThreshold: 300, ExitThreshold: 120, WarningCallback: handle.exit, ExitCallback: function () { return window.location.reload(); } }), _jsx(LoadingView, { LaunchRequestStatus: props.LaunchRequestStatus, StreamerStatus: props.StreamerStatus }), _jsx(VideoStream, { VideoRef: videoRef, Emitter: props.InputEmitter, Stream: props.VideoStream, UseNativeTouchEvents: props.UseNativeTouchEvents, UsePointerLock: props.UsePointerLock, PointerLockRelease: props.PointerLockRelease, Resolution: streamResolutionConfiguration(props.Resolution) }), _jsx(Button, __assign({ onClick: handle.enter, style: { position: 'absolute', top: 10, right: 10 }, className: isIPhone || handle.active || props.StreamerStatus !== StreamerStatus.Connected ? 'hidden' : '' }, { children: _jsx(Icon, { name: "expand" }) }))] })) })));
};
// Initialize audio.
// load() must be called from a user interaction, especially to retain iOS audio
// this can be 'mouseup', 'touchend' or 'keypress'
// Pass the audioStream created from useStreamer as the srcObject to play game audio.
var audio = new Audio();
audio.autoplay = true;
audio.volume = 0.5;
// Parse query parameters
var query = qs.parse(window.location.search);
var clientOptions = new ClientOptions();
clientOptions.LaunchType = (_a = query['launchType']) !== null && _a !== void 0 ? _a : client.launchType;
if (query['collaboration'] && query['collaboration'] === 'true') {
    clientOptions.LaunchType = 'local';
}
clientOptions.Endpoint = (_b = query['endpoint']) !== null && _b !== void 0 ? _b : client.endpoint;
clientOptions.ProjectId = (_c = query['projectId']) !== null && _c !== void 0 ? _c : client.projectId;
clientOptions.ModelId = (_d = query['modelId']) !== null && _d !== void 0 ? _d : client.modelId;
clientOptions.Version = (_e = query['version']) !== null && _e !== void 0 ? _e : client.version;
clientOptions.EnvironmentId = (_f = query['environmentId']) !== null && _f !== void 0 ? _f : client.environmentId;
clientOptions.Resolution = (_g = query['resolution']) !== null && _g !== void 0 ? _g : client.resolution;
clientOptions.Resolution = (_h = clientOptions.Resolution) !== null && _h !== void 0 ? _h : Resolution.fhd;
// use client json config if usePointerLock query string parameter is undefined, else use query string parameter. Default to false if non are present
clientOptions.UsePointerLock =
    (_j = (query['usePointerLock'] === undefined ? client.usePointerLock : query['usePointerLock'] === 'true')) !== null && _j !== void 0 ? _j : true;
// release the pointer lock on mouse up if true
clientOptions.PointerLockRelease =
    (_k = (query['pointerLockRelease'] === undefined ? client.pointerLockRelease : query['pointerLockRelease'] === 'true')) !== null && _k !== void 0 ? _k : true;
clientOptions.ForceRelay = (_l = query['forceRelay'] !== undefined) !== null && _l !== void 0 ? _l : false;
clientOptions.UseNativeTouchEvents =
    (_m = (query['useNativeTouchEvents'] === undefined
        ? client.useNativeTouchEvents
        : query['useNativeTouchEvents'] === 'true')) !== null && _m !== void 0 ? _m : false;
// Initialize platform reference
var platform = new PlatformNext();
platform.initialize({ endpoint: clientOptions.Endpoint || 'https://api.pureweb.io' });
var App = function () {
    var _a = useState(false), modelDefinitionUnavailable = _a[0], setModelDefinitionUnavailable = _a[1];
    var _b = useState(new UndefinedModelDefinition()), modelDefinition = _b[0], setModelDefinition = _b[1];
    var _c = useState(), availableModels = _c[0], setAvailableModels = _c[1];
    var _d = useState(), launchRequestError = _d[0], setLaunchRequestError = _d[1];
    var streamerOptions = DefaultStreamerOptions;
    useAsyncEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        var models, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!clientOptions.ProjectId) return [3 /*break*/, 6];
                    logger.info('Initializing available models: ' + clientOptions.ProjectId);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, platform.useAnonymousCredentials(clientOptions.ProjectId, clientOptions.EnvironmentId)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, platform.connect()];
                case 3:
                    _a.sent();
                    logger.info('Agent Connected: ' + platform.agent.id);
                    streamerOptions.iceServers = platform.agent.serviceCredentials.iceServers;
                    streamerOptions.forceRelay = clientOptions.ForceRelay;
                    return [4 /*yield*/, platform.getModels()];
                case 4:
                    models = _a.sent();
                    setAvailableModels(models);
                    logger.debug('Available models', models);
                    return [3 /*break*/, 6];
                case 5:
                    err_1 = _a.sent();
                    logger.error(err_1);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [clientOptions]);
    useEffect(function () {
        if (availableModels === null || availableModels === void 0 ? void 0 : availableModels.length) {
            var selectedModels = availableModels.filter(function (model) {
                if (clientOptions.ModelId === model.id) {
                    // If there is a version specified and we encounter it
                    if (clientOptions.Version && clientOptions.Version === model.version) {
                        return true;
                    }
                    // If there is no version specified and we find the primary version
                    if (!clientOptions.Version && model.active) {
                        return true;
                    }
                }
                return false;
            });
            if (selectedModels === null || selectedModels === void 0 ? void 0 : selectedModels.length) {
                setModelDefinition(selectedModels[0]);
            }
            else {
                setModelDefinitionUnavailable(true);
            }
        }
    }, [availableModels]);
    var launchRequestOptions = {
        virtualizationProviderOverride: query['virtualizationProviderOverride']
    };
    var _e = useLaunchRequest(platform, modelDefinition, launchRequestOptions), status = _e[0], launchRequest = _e[1], queueLaunchRequest = _e[2];
    var _f = useStreamer(platform, launchRequest, streamerOptions), streamerStatus = _f[0], emitter = _f[1], videoStream = _f[2], audioStream = _f[3], messageSubject = _f[4];
    var _g = useState(false), loading = _g[0], setLoading = _g[1];
    useEffect(function () {
        if (streamerStatus === StreamerStatus.Failed) {
            platform.disconnect();
        }
    }, [streamerStatus]);
    if (audioStream) {
        audio.srcObject = audioStream;
    }
    var launch = function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    audio.load();
                    if (!(clientOptions.LaunchType !== 'local')) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, queueLaunchRequest()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    err_2 = _a.sent();
                    setLaunchRequestError(err_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // Log status messages
    useEffect(function () {
        logger.info('Status', status, streamerStatus);
    }, [status, streamerStatus]);
    // Subscribe to game messages
    useEffect(function () {
        var subscription = messageSubject.subscribe(function (value) {
            logger.info('Message: ' + value);
        }, function (err) {
            logger.error(err);
        });
        return function () {
            subscription.unsubscribe();
        };
    }, [messageSubject]);
    // Notify user of missing or errors in configuration
    if (!clientOptions.isValid() && clientOptions.LaunchType !== 'local') {
        return (_jsx("div", __assign({ style: {
                display: 'flex',
                height: '100%',
                overflow: 'none',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center'
            } }, { children: _jsxs("p", { children: ["Your client has one or more configuration errors. Please consult the", ' ', _jsx("a", __assign({ href: "https://www.npmjs.com/package/@pureweb/cra-template-pureweb-client" }, { children: " README " })), " for details on how to configure the client template."] }) })));
    }
    if (modelDefinitionUnavailable && clientOptions.LaunchType !== 'local') {
        return (_jsx("div", __assign({ style: {
                display: 'flex',
                height: '100%',
                overflow: 'none',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center'
            } }, { children: _jsx("span", { children: "The model that you have requested does not exist" }) })));
    }
    if (launchRequestError) {
        return (_jsx("div", __assign({ style: {
                display: 'flex',
                height: '100%',
                overflow: 'none',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center'
            } }, { children: _jsx("span", { children: process.env.NODE_ENV === 'development'
                    ? "There was an error with the launch request: ".concat(launchRequestError)
                    : 'It appears the requested model is currently not online as per your set schedule. Please contact support if it should be available.' }) })));
    }
    // Begin connection
    if (streamerStatus === StreamerStatus.Disconnected) {
        return (_jsx("div", __assign({ style: {
                display: 'flex',
                height: '100%',
                overflow: 'none',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center'
            } }, { children: _jsx("h2", { children: "Disconnected from stream" }) })));
    }
    if (streamerStatus === StreamerStatus.Failed) {
        return (_jsxs("div", __assign({ style: {
                display: 'flex',
                height: '100%',
                overflow: 'none',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center'
            } }, { children: [_jsx("h2", { children: "Failure during stream" }), _jsx("h2", { children: "Please refresh to request a new session" })] })));
    }
    if (streamerStatus === StreamerStatus.Withdrawn) {
        return (_jsx("div", __assign({ style: {
                display: 'flex',
                height: '100%',
                overflow: 'none',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center'
            } }, { children: _jsx("h2", { children: "Streamer contribution withdrawn" }) })));
    }
    if (loading) {
        return (_jsx(EmbeddedView, { VideoStream: videoStream, StreamerStatus: streamerStatus, LaunchRequestStatus: status, InputEmitter: emitter, UseNativeTouchEvents: clientOptions.UseNativeTouchEvents, UsePointerLock: clientOptions.UsePointerLock, PointerLockRelease: clientOptions.PointerLockRelease, Resolution: clientOptions.Resolution }));
    }
    else if (clientOptions.LaunchType !== 'local' && !availableModels) {
        return (_jsx("div", __assign({ style: {
                display: 'flex',
                height: '100%',
                overflow: 'none',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center'
            } }, { children: _jsx("h2", { children: "Chargement..." }) })));
    }
    else if (clientOptions.LaunchType !== 'local' && !(availableModels === null || availableModels === void 0 ? void 0 : availableModels.length)) {
        return (_jsx("div", __assign({ style: {
                display: 'flex',
                height: '100%',
                overflow: 'none',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center'
            } }, { children: _jsx("h2", { children: "No models are currently available in this environment." }) })));
    }
    else {
        return _jsx(LaunchView, { Launch: launch });
    }
};
var AppWrapper = function () {
    return System.IsBrowserSupported() ? (_jsx(App, {})) : (_jsx("div", __assign({ className: "ui red segment center aligned basic" }, { children: _jsx("h2", __assign({ className: "header" }, { children: "Your browser is currently unsupported" })) })));
};
export default AppWrapper;
